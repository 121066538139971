import Alpine from 'alpinejs'
 
window.Alpine = Alpine

window.bookData = function() {
    return {
        async init() {
            this.loading = true
            try {
                const res = await fetch('/currently-reading')
                const booksData = await res.json()
                this.books = booksData.data ?? []
                this.error = null
            } catch (e) {
                this.error = e
                this.books = []
            }

            this.loading = false
        },
        error: null,
        loading: true,
        books: []
    }
}
 
Alpine.start()